import beary from './beary-boudoir.png';
import bearySmall from './beary-boudoir-small.png';
import beary2 from './beary2.png';
import beary2Small from './beary2-small.png';
import beary3 from './beary3.png';
import beary3Small from './beary3-small.png';
import beary4 from './beary4.png';
import beary4Small from './beary4-small.png';
import beary5 from './beary5.png';
import beary5Small from './beary5-small.png';
import beary6 from './beary6.png';
import beary6Small from './beary6-small.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="website"><span className="only">Only</span><span className="beary">Beary</span></div>
        <div className="tagline">Premium Beary content subscription service</div>
        <img src={beary} style={{ 
          maxWidth: '100%',
          }} className="image-large"/>
        <img src={bearySmall} style={{ 
          maxWidth: '100%',
          }} className="image-small"/>
        <div className="taglineSubtitle">He'll do <span style={
          {
            fontStyle: 'italic',
          }
        }>anything</span> for treats</div>
        <img src={beary2} style={{ maxWidth: '100%'}}  className="image-large" />
        <img src={beary2Small} style={{ maxWidth: '100%'}}  className="image-small" />
        <div className="taglineSubtitle">He'll <span style={
          {
            fontStyle: 'italic',
          }
        }>bear it all</span> for you</div>
        <img src={beary3} style={{ maxWidth: '100%'}} className="image-large"/>
        <img src={beary3Small} style={{ maxWidth: '100%'}}  className="image-small" />
        <div className="taglineSubtitle">See <span style={
          {
            fontStyle: 'italic',
          }
        }>how many balls</span> Beary can fit in his mouth</div>
        <img src={beary6} style={{ maxWidth: '100%'}} className="image-large"/>
        <img src={beary6Small} style={{ maxWidth: '100%'}} className="image-small"/>
        <div className="taglineSubtitle">He wants <span style={
          {
            fontStyle: 'italic',
          }
        }>so bad</span> to be a <span style={
          {
            fontStyle: 'italic',
          }
        }>good</span> boy </div>
        <img src={beary4} style={{ maxWidth: '100%'}} className="image-large"/>
        <img src={beary4Small} style={{ maxWidth: '100%'}} className="image-small"/>
        <div className="taglineSubtitle">DM for anything special</div>
        <img src={beary5} style={{ maxWidth: '100%'}} className="image-large"/>
        <img src={beary5Small} style={{ maxWidth: '100%'}} className="image-small"/>
      </header>
    </div>
  );
}

export default App;
